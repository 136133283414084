import routing from '@toyokumo/fos-router';
import { ImpressionListEventType } from '@libs/Enum/ImpressionLists';
import { getProfileDetailUrl } from '@libs/getProfileDetailUrl';
import { AbstractProfile } from '../../graphql/generated/types/graphqlTypes';

const pushGACompanyImpressionListEvent = (
  action: ImpressionListEventType,
  profile: AbstractProfile,
  impressionListName: string,
  impressionListCompanyVariant: string,
  locale: string,
  position: number,
  linkUrl: string | undefined = undefined,
  additionalProperties: object = {},
) => {
  const dataLayer = window.dataLayer || [];
  const eventName = `company_impression_${action}`;
  dataLayer.push({
    event: eventName,
    hit: {
      company_id: profile.urlIdentifier,
      company_name: `${profile.expandedName} ${profile.g2IdsAsIntegers.join(', ')}`,
      company_variant: impressionListCompanyVariant,
      company_tags: 'missing',
      list_name: impressionListName,
      action: action.toString(),
      link_url:
        action === ImpressionListEventType.click
          ? linkUrl ?? `https://${routing.getHost()}${getProfileDetailUrl(locale, profile)}`
          : undefined,
      list_position: position,
      ...additionalProperties,
    },
    _clear: true,
  });
};

export default pushGACompanyImpressionListEvent;
